import styled from "styled-components";
import hexToRgbA, { hexToRgba, lightOrDark1 } from "../HexToRGBA";

export const CardWrapper = styled.div`
display: flex;
padding: 0.8rem;
border-radius: 0.5rem;
margin: 1%;
background-color: ${props => hexToRgba(props.theme.secondaryColor, 0.05)};
justify-content: center;
align-content: center;
align-items: center;
 &: hover {
        cursor: pointer;
        transition: all 0.2s ease-in-out;
    transform: scale(1.05);
    border: solid ${props => hexToRgba(props.theme?.secondaryBg, 0.7)} 1px;
    
    }
`

export const CardContainer = styled.div`
background: ${props=> props.color} url(${props=> props.img}) center;
background-size: cover ;
background-position: center;
cursor: pointer;
padding: 3rem 0 0;
width: 15rem;
height: 15rem;
overflow: hidden;
max-width: 15rem;
white-space: normal;
border-radius: ${ props => props.type === "artist" ? "50px 5px 50px 5px":  props.type === "genre" ? "50%" : "5px"};
align-items: center;
justify-content: center;
transition: transform 500ms ease;
&:hover{
    transition: all 0.2s ease-in-out;
    transform: scale(1.05);
}
&:focus{     
    transition: all 0.2s ease-in-out;
    transform: scale(1.05);
}

`
export const CardContent = styled.div`
padding: 1.5rem;
opacity: 0;
white-space: normal;
background: ${props => hexToRgbA(props.theme.mainBg, 0.6)};
bottom: -50%;
transition: transform 500ms ease;
@media screen and (max-width: 960px) {
    opacity: 1;
    bottom: 0%; 
    background: ${props => hexToRgbA(props.theme.mainBg, 0.8)};
}

${CardContainer}: hover &{
    bottom: 0%;
    opacity: 1;
    background: ${props => hexToRgbA(props.theme.mainBg, 0.8)};
}

`

export const CardTitle = styled.h1`
    display: flex;
    word-wrap: break-word;
    position: relative;
    white-space: normal;
    color: ${props => props.theme.secondaryBg};
    font-size: 1.2rem;
    &:hover {
        transform: scale(1.02);
        transition: transform 0.3s ease;
    }
    align-items: center;
    justify-content: ${props =>  (props.type === "genre" || props.type === "artist" )? 'center': 'flex-start'};
    &:after{
        content: "";
        transform: scaleX(0);
        transform-origin: left;
        transition: transform 500ms ease;
        position: absolute;
        height: 2px;
        color: ${props => props.theme.secondaryBg};

        background: ${props => props.theme.secondaryColor};
        left: calc(1.5 * -1);
        width: 100%;
        bottom: 0; 

        ${CardContainer}: hover &{
            transform: scaleX(1);
            left: 0;
        }

    }
`
export const VerifiedBadge = styled.div`
display: flex;
background: url(${props => props.badge});
background-size: cover;
position: relative;
width: 1.5rem;
height: 1.5rem;
@media screen and (max-width: 960px) {
    font-size: 1rem;
  }
`

export const CardBody = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 0.85rem;
    word-wrap: break-word;
    color: ${props => hexToRgba(props.theme.mainColor)}
    width: 50%;
    height: 100%;
    align-content: space-between;
    justify-content: space-between;
`

export const CardButton = styled.div`
    cursor:pointer;
    display: flex;
    text-decoration: none;
    color: ${props => props.theme.mainBg};
    background-color: ${props => props.theme.mainColor};
    padding: 0.5em 1.25em;
    border-radius: 0.25rem;
    width: 50%;
    &: hover {
        background-color: ${props => props.theme.accentColor};
    }

`

export const CardLink = styled.a`
    curstor: pointer;
    display: flex;
    color: ${props => props?.color ? props.color : props.theme.accentColor};
    text-decoration: none;
    &: hover {
        text-decoration: underline;
        
    }

`
export const CardText = styled.p`
    display: flex;
    color: ${props => props?.color ? props.color : props.theme.secondaryBg};
    text-decoration: none;
    white-space: break-spaces;

`

export const SoldOutText = styled.h4`
    display: flex;
    font-size: 1.2rem;
    margin-bottom: 15px;
    white-space: normal;
    word-wrap: break-word;
    text-align: center;
    color: ${props => props.theme.errorColor};

`

export const PreReleaseBadge = styled.div`
display: flex;
background-color: ${props => props.theme.mainColor};
font-size: 0.7rem;
line-height: 1.5;
font-weight: 700;
width: fit-content;
letter-spacing: 0em;
margin-top: auto;
margin-left: 3%;
margin-bottom: 4px;
padding 0 4px;
grid-area: badge;
z-index: 1;
color: ${props => lightOrDark1(props.theme.mainColor) === 'light' ? 'black' : 'white'};
white-space: nowrap;
border-radius: 3px;
justify-self: flex-end;

`