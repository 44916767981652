import { collection } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import {
  BsArrowLeftShort,
  BsArrowRepeat,
  BsArrowRightShort,
  BsFillSkipEndFill,
  BsFillSkipStartFill,
  BsShuffle,
} from "react-icons/bs";
import { FaPause, FaPlay } from "react-icons/fa";
import { useSonarMusePlayer } from "../../../hooks/AudioHook";
import useMusic from "../../../hooks/useMusic";
import { getSongTitle, stringFormatter } from "../../../utils";
import { CONTENT_TYPES, MUSIC_PLAYING_FROM } from "../../../utils/Enum";
import ImageUtils from "../../../utils/ImageUtils";
import { Audio } from "../../AudioPlayer/AudioPlayerElements";
import { PlaylistWrapper } from "../../AudioPlayer/AudioPlayerPlaylist/AudioPlayerPlaylistElements";
import { useAudio } from "../../AudioPlayer/context/AudioContext";
import { Button1 } from "../../ButtonElement";
import CollectionAudioPlayer from "../../CollectionAudioPlayer";
import {
  CollectionSongList,
  MusicStoreSongList,
} from "../../ContentList/SongList";
import LoadingScreen, { ModalLoadingScreen } from "../../LoadingScreen";
import { MusicPlayPauseButton } from "../../MusicPage/MusicPageElements";
import { Nav, NavContainer, NavLink } from "../../NavLink/NavLinkElements";
import OptionModal from "../../OptionModal";
import RecordPlayer from "../../RecordPlayer";
import MusicStoreAudioPlayer from "./MusicStoreAudioPlayer";
import {
  AudioControlButton,
  AudioPlayPauseButton,
  ControlButtonsContainer,
} from "./MusicStorePlayerElements";
import VideoPlayer from "../../VideoPlayer";

const MusicStorePlayer = ({ music, type, name }) => {
  const { defaultImg } = ImageUtils();
  const { getSongs, getAlbum } = useMusic();
  const [currentTab, setTab] = useState("cover");
  const [isLoaded, setLoaded] = useState(false);
  const {
    timeJump,
    currentSong,
    isPlaying,
    duration,
    currentTime,
    progressBar,
    audioPlayer,
    changeRange,
    setTimeJump,
    volume,
    isRepeat,
    calcTime,
    backThirty,
    forwardThirty,
    isPodcast,
    isShuffle,
    nextSong,
    handleToggle,
    prevSong,
    playlist,
    setPlaylist,
    setCurrentSong,
    timeTravel,
    togglePlayPause,
    toggleMute,
    toggleVolume,
    handleEnd,
    isOpen,
    closeViewer,
    stop,
    isMute,
    openViewer,
  } = useSonarMusePlayer();

  useEffect(() => {
    loadMusic();
    return () => {}
  }, [!isLoaded]);

  const loadMusic = async() => {
    switch (type) {
      case CONTENT_TYPES.SONG:
        setPlaylist(music.map(s => {
          return {...s,
            playingFrom: {
              playingFrom: MUSIC_PLAYING_FROM.MUSIC_STORE,
              id: type,
            }}
        }));
        setCurrentSong(0);
        setLoaded(true);
        break;
      case CONTENT_TYPES.ALBUM:
        if(music?.some((song) => song?.songId)){
          setPlaylist(music?.filter((s) =>
            (!s?.isPreRelease || (s?.isPreRelease && s?.isPreview)))?.map(s => {
            return {...s,
              playingFrom: {
                playingFrom: MUSIC_PLAYING_FROM.MUSIC_STORE,
                id: type,
              }}
          }));
          setCurrentSong(0);
          setLoaded(true);
          return
        }
        return getSongs(music)
        .then((songs) => {
          setPlaylist(songs?.filter((s) =>
            (!s?.isPreRelease || (s?.isPreRelease && s?.isPreview)))?.map(s => {
            return {...s,
              playingFrom: {
                playingFrom: MUSIC_PLAYING_FROM.MUSIC_STORE,
                id: type,
              }}
          }));
          setCurrentSong(0);
          setLoaded(true);
        })
        .catch((err) => {});
      case CONTENT_TYPES.COLLECTION:
        let collectionSongs = []
        if(music?.songs?.length > 0){
        await getSongs(music?.songs)
          .then((songs) => {

           collectionSongs.push(...songs.map(s => {
            return {...s,
              playingFrom: {
                playingFrom: MUSIC_PLAYING_FROM.MUSIC_STORE,
                id: type,
              }}
          }))
            
          })
          .catch((err) => {});
        }if(music?.albums?.length > 0){

          for(let i = 0; i < music?.albums?.length; i++){
            let album = await getAlbum(music?.albums[i]).then((data) => {
              return data
            })
            if(album?.songs?.length > 0){
              collectionSongs.push(...album.songs.map(s => {
                return {...s,
                  playingFrom: {
                    playingFrom: MUSIC_PLAYING_FROM.NFT,
                    id: type,
                  }}
              }))
            }
          }
        }
        if(collectionSongs?.length > 0){
        setPlaylist(collectionSongs);
        setCurrentSong(0);
        setLoaded(true);
        }else{
            isLoaded(true)
            }
        break;
    
      case CONTENT_TYPES.NFTOKEN:
        let tokenSongs = []
        if(music?.songs?.length > 0){

           tokenSongs.push(...music?.songs.map(s => {
            return {...s,
              playingFrom: {
                playingFrom: MUSIC_PLAYING_FROM.NFT,
                id: type,
              }}
          }))

        }if(music?.albums?.length > 0){
          for(let i = 0; i < music?.albums?.length; i++){
            let album = music?.albums[i]
            if(album?.songs?.length > 0){
              tokenSongs.push(...album.songs.map(s => {
                return {...s,
                  playingFrom: {
                    playingFrom: MUSIC_PLAYING_FROM.NFT,
                    id: type,
                  }}
              }))
            }
          }
        }
        if(tokenSongs?.length > 0){
        setPlaylist(tokenSongs);
        setCurrentSong(0);
        setLoaded(true);
        }else{
            isLoaded(true)
            }
        break;
    
      default:
        break;
    }

    return;
  };

  const value = {
    timeJump,
    currentSong,
    isPlaying,
    duration,
    currentTime,
    progressBar,
    audioPlayer,
    changeRange,
    setTimeJump,
    handleEnd,
    volume,
    isRepeat,
    calcTime,
    backThirty,
    forwardThirty,
    isPodcast,
    isShuffle,
    nextSong,
    handleToggle,
    prevSong,
    playlist,
    setPlaylist,
    togglePlayPause,
    toggleMute,
    toggleVolume,
    isOpen,
    closeViewer,
    isMute,
    openViewer,
  };

  const togglePlay = () => {
    let playingFrom = {
      playingFrom: MUSIC_PLAYING_FROM.NFT,
      id: type,
    };
    localStorage.setItem("isPlayingFrom", JSON.stringify(playingFrom));
    if (type === CONTENT_TYPES.SONG) {
      setCurrentSong(0);
    }
    if (isPlaying) {
      handleToggle("play-pause", false);
    } else {
      handleToggle("play");
    }
  };

  return (
    <OptionModal heading={`Playing ${name}`} additionalText={isLoaded && (type ===CONTENT_TYPES.COLLECTION || type ===CONTENT_TYPES.ALBUM || type === CONTENT_TYPES.NFTOKEN) && `${getSongTitle(playlist[currentSong])}`}>
      {playlist?.length > 0 && isLoaded && (
        <>
          <div style={{ marginBottom: "2%", marginTop: "2%", justifyContent: "center", alignContent: "center" }}>
            {currentTab === "cover" && (
              <RecordPlayer
                onClick={() => togglePlay()}
                stop={!isPlaying}
                albumArt={
                  playlist[currentSong]?.albumArt
                    ? playlist[currentSong]?.albumArt
                    : defaultImg
                }
                alt={playlist[currentSong]?.songName}
              />
            )}
            {currentTab === "tracklist" && (
              <MusicStoreSongList
                type={CONTENT_TYPES.SONG}
                currentSong={currentSong}
                isPlaying={isPlaying}
                playlist={playlist}
                content={playlist}
              />
            )}
            {currentTab === "lyrics" && (
              <PlaylistWrapper>{stringFormatter(playlist[currentSong]?.lyrics)}</PlaylistWrapper>
            )}
            {currentTab === "video" && (
              <VideoPlayer videoContent={playlist[currentSong]?.video} isPlaying={isPlaying} stopMainPlayer={() => {
                if(isPlaying){
                togglePlayPause()
              }
              }}/>
            )}
          </div>
          <NavContainer>
            <Nav>
              <NavLink
                active={currentTab === "cover"}
                onClick={() => setTab("cover")}
              >
                Cover Art
              </NavLink>
              {playlist[currentSong]?.video && <NavLink
                active={currentTab === "video"}
                onClick={() => setTab("video")}
              >
                Video
              </NavLink>}
              {playlist[currentSong]?.lyrics?.length > 0 && <NavLink
                active={currentTab === "lyrics"}
                onClick={() => setTab("lyrics")}
              >
                Lyrics
              </NavLink>}
              <NavLink
                active={currentTab === "tracklist"}
                onClick={() => setTab("tracklist")}
              >
                Tracklist
              </NavLink>
            </Nav>
          </NavContainer>
          {/* <Button1 style={{ marginTop: "2%", marginBottom: "2%" }} onClick={() => togglePlay()}>
            {isPlaying ? (
              <>
                <FaPause style={{ position: "relative", left: "1%", marginRight: "0.5%" }} /> Pause
              </>
            ) : (
              <>
                <FaPlay style={{ position: "relative", left: "1%", marginRight: "0.5%" }} /> Play
              </>
            )}
          </Button1> */}
          <ControlButtonsContainer>
          {((music.length > 1) || (music?.albums?.length > 0) || (music?.songs?.length > 1)) && <AudioControlButton onClick={() => handleToggle("prev", true)}>
              <BsFillSkipStartFill />
            </AudioControlButton>}
            <AudioControlButton 
              onClick={() => togglePlay()}
            >
              {isPlaying ? (
                <FaPause />
              ) : (
                <FaPlay style={{ position: "relative", left: "1%" }} />
              )}
            </AudioControlButton >
            {((music.length > 1) || (music?.albums?.length > 0) || (music?.songs?.length > 1)) && <AudioControlButton onClick={() => handleToggle("next", true)}>
              <BsFillSkipEndFill />
            </AudioControlButton>}
          </ControlButtonsContainer>
          <MusicStoreAudioPlayer {...value} />
        </>
      )}
      {!isLoaded && <ModalLoadingScreen text={"Loading Music. Please Wait..."} transparent />}
    </OptionModal>
  );
};

export default MusicStorePlayer;
